<template>
<div>
  <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :is-full-page="true"></loading>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          color="green"
          :title="machine.name"
          :text="machine.serialVendingNumber"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr v-bind:class="{ 'light-blue lighten-5': item.status === 'OutOfStock', 'red lighten-5': item.status === 'Blocked'}">
                <td>
                  <v-btn title="Редактировать мэппинг" color="primary" small flat icon @click="pickToEditShopItem(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  
                </td>
                <td>
                  {{ item.button }}
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.price }}</td>
                <td>{{ actionTitle(item.action) }}</td>
                <td>{{ item.categoryName }} </td>
                <td>
                  <v-btn title="Редактировать мэппинг" color="primary" small flat icon @click="pickToEditShopItem(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn color="error" small flat icon @click="pickToDeleteMapping(item)" >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
      
    </v-layout>
  </v-container>
  <v-container fluid>
      <v-layout row wrap>
          <v-btn title="Добавить новую позицию" color="#66bb6a" @click="pickToAddShopItem()">Добавить</v-btn>
          <v-btn title="Мэппинг торгового автомата" color="primary" :to="{ name: 'Торговый автомат', params: { id: id }}">Мэппинг</v-btn>
      </v-layout>
    </v-container>
  <v-dialog v-model="addMappingDialog" max-width="600">
        <v-card>
            <v-toolbar flat color="#66bb6a">
              <v-toolbar-title left color="blue" class="text--white">Новая позиция</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="addMappingDialog = false">
                  <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          <v-card-text>
            <v-alert
              :value="error != null"
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-form ref="form" v-model="valid">
                          
                          
                <v-layout wrap>
                    <v-flex xs12 sm4 class="font-weight-bold">Товар</v-flex>
                    <v-flex xs12 sm8>
                        <v-autocomplete
                          label="Выберите товар"
                          v-model="newMap.shopItemId"
                          :items="shopItems"
                          item-text="name"
                          item-value="id"
                          @change="onChange"
                          :rules="rules.required"
                        >
                          <template v-slot:item="item">
                            <v-chip
                            >
                              <v-avatar>
                                <img :src="imageUrl(item.item.image)">
                              </v-avatar>
                              {{ item.item.name }}
                            </v-chip>
                          </template>
                        </v-autocomplete>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 sm4 class="font-weight-bold">Цена</v-flex>
                  <v-flex xs12 sm8>
                        <v-text-field
                                label="Выберите цену" 
                                v-model="newMap.price" 
                                
                                suffix="₽"
                                type="number"></v-text-field>
                  </v-flex>
                </v-layout>
                
                
                
                <v-layout wrap>
                    <v-flex xs12 sm4 class="font-weight-bold">Кнопка</v-flex>
                    <v-flex xs12 sm8>
                         <v-text-field :rules="rules.required"
                                  label="Кнопка" 
                                  v-model="newMap.button" 
                                  type="number"></v-text-field>
                    </v-flex>
                </v-layout>
               
                <v-layout wrap>
                  <v-flex xs12 sm4 class="font-weight-bold">Действие</v-flex>
                  <v-flex xs12 sm8>
                      <v-select label="Выберите действие"
                                :items="actions"
                                item-text="title"
                                item-value="name"
                                v-model="newMap.action"></v-select>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" @click="addMapping" class="white--text" :disabled="!valid || isSaving">Сохранить</v-btn>
                    <v-btn color="red darken-1" @click.native="addMappingDialog = false" class="white--text">Отмена</v-btn>
                </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
    </v-dialog>
  <v-dialog v-model="editMappingDialog" max-width="600">
    <v-card>
        <v-toolbar flat color="#66bb6a">
          <v-toolbar-title left color="blue" class="text--white">Обновить позицию</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="editMappingDialog = false">
              <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid">
            <v-alert
              :value="error != null"
              type="error"
            >
              {{ error }}
            </v-alert>          
                      
            <v-layout row wrap py-1>
                <v-flex xs12 sm4 class="font-weight-bold">Товар</v-flex>
                <v-flex xs12 sm8>
                    <!-- <v-select label="Выберите товар"
                              :items="shopItems"
                              :rules="rules.required"
                              item-text="name"
                              item-value="id"
                              disabled
                              v-model="editMap.shopItemId"></v-select> -->
                  <v-autocomplete
                    label="Выберите товар"
                    v-model="editMap.shopItemId"
                    :items="shopItems"
                    item-text="name"
                    item-value="id"
                    single-line
                    solo
                    hide-details
                    :rules="rules.required"
                    @change="onChangeForEdit"
                  >
                    <template v-slot:item="item">
                      <v-chip
                      >
                        <v-avatar>
                          <img :src="imageUrl(item.item.image)">
                        </v-avatar>
                        {{ item.item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-flex>
            </v-layout>
            
            <v-layout row wrap py-1>
                <v-flex xs6 sm6 class="font-weight-bold">Цена</v-flex>
                <v-flex xs6 sm6 class="font-weight-bold">Кнопка</v-flex>
                <v-flex xs6 sm6>
                      <v-text-field
                        single-line
                        solo
                        hide-details
                        label="Выберите цену" 
                        v-model="editMap.price" 
                        suffix='₽'
                        type="number"></v-text-field>
                  <!-- <v-select label="Выберите цену"
                                  :items="prices"
                                  
                                  v-on:change="changeEditedPrice"
                                  v-model="editMap.price"></v-select> -->
                </v-flex>
                
                <v-flex xs6 sm6>
                      <!-- <v-text-field :rules="rules.required"
                        single-line
                        solo
                        hide-details
                              label="Кнопка" 
                              v-model="editMap.button" 
                              ></v-text-field> -->
                  <v-select label="Выберите ячейку"
                    :items="cells"
                    :rules="rules.required"
                    single-line
                    solo
                    hide-details
                    item-text="name"
                    item-value="name"
                    disabled
                    v-model="editMap.button"></v-select>
                </v-flex>
            </v-layout>


            <v-layout wrap>
              <v-flex xs12 sm4 class="font-weight-bold">Действие</v-flex>
              <v-flex xs12 sm8>
                  <v-select label="Выберите действие"
                            :items="actions"
                            item-text="title"
                            item-value="name"
                            v-model="editMap.action"></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" @click="editMapping" class="white--text" :disabled="!valid || isSaving">Сохранить</v-btn>
                <v-btn color="red darken-1" @click.native="editMappingDialog = false" class="white--text">Отмена</v-btn>
            </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="deleteMappingDialog" max-width="600">
    
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Удаление позиции</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="deleteMappingDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-layout wrap>
            Вы действительно хотите удалить позицию меню для {{ editMap.name }}?
          </v-layout>
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" @click="deleteMapping" class="white--text" :disabled="isSaving">Удалить</v-btn>
            <v-btn color="red darken-1" @click.native="deleteMappingDialog = false" class="white--text">Отмена</v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
  </v-dialog>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
    // Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  data: () => ({
    isLoading: false,
    addMappingDialog: false,
    editMappingDialog: false,
    deleteMappingDialog: false,
    resetReaderDialog: false,
    syncMappingDialog: false,
    isSaving: false,
    machine: {},
    headers: [
      {
        sortable: false,
        text: ''
      },
      {
        sortable: true,
        text: '▦',
        value: 'button'
      },
      {
        sortable: true,
        text: 'Товар',
        value: 'name'
      },
      {
        sortable: true,
        text: 'Цена',
        value: 'price'
      },
      {
        sortable: true,
        text: 'Действие',
        value: 'command'
      },
      {
        sortable: true,
        text: 'Категория',
        value: 'categoryName'
      },
      {
        sortable: false,
        text: ''
      }
    ],
    newMap: {
      shopItemId: null,
      vendingMachineId: null,
      button: 0,
      price: 0
    },
    editMap: {
      id: null,
      shopItemId: null,
      vendingMachineId: null,
      button: 0,
      price: 0,
    },
    items: [],
    shopItems: [],
    categoryItems: [],
    actions: [{ id: "", name: null, title: "<нет>" }],
    cells: [],
    rules: {
        required: [v => !!v || 'Поле обязательно',],
    },
    valid: false,
    error: null,
    id: null,
  }),
  components: {
    Loading
  },
  computed: {
    prices() {
      let array = [...new Set(this.cells.filter(x=>x.active == true).map(x=>x.price).sort(this.sortCompareNumbers))]
      console.log(array)
      return array
    },
    fltrdCellsAdd (){
      return this.cells.filter(x=>x.price == this.newMap.price && x.capacity >= this.newMap.amount && !this.mappingItemsButtons.includes(x.name)).sort(this.sortFunction)
    },
    fltrdCellsEdit(){
      return this.cells.filter(x=>x.price == this.editMap.price && x.capacity >= this.editMap.amount).sort(this.sortFunction)
    },
    mappingItemsButtons(){
      return [...new Set(this.items.map(x=>x.button))]
    },
  },
  created (){
    this.id = this.$route.params.id;
    this.newMap.vendingMachineId = this.id
  },
  async mounted () {
    await this.getCategories()
    await this.getActions()
    this.getVendingMachine()
    this.getShopItems()
    this.getVendingMachineShopItems()
    this.getVendingMachineCells()
  },
  methods: {
    actionTitle(actionName){
      if (actionName){
        if (this.actions && this.actions.length > 0){
          const cmd = this.actions.find(x=>x.name == actionName)
          if (cmd) {
            return cmd.title
          }
        }
      }

      return '<нет>';
    },
    imageUrl: function (imgUrl) {
      return process.env.VUE_APP_IMAGE_URL + imgUrl
    },
    getCategoryName(id){
      let category = this.categoryItems.find(x=>x.id == id)

      return category ? category.name : '?'
    },
    getVendingMachine (){
      this.$http.get('/vendingmachines/' + this.id)
        .then(response => {
          this.machine = response.data
        })
    },
    getVendingMachineShopItems(){
      this.$http.get('/vendingmachines/' + this.id + '/shop-items' )
        .then(response => {
          this.items = response.data 
        })
    },
    getShopItems(){
      this.$http.get('/shopitems')
        .then(response => {
          this.shopItems = response.data
        })
    },
    getVendingMachineCells(){
      this.$http.get('/vendingmachines/' + this.id + '/cells' )
        .then(response => {
          this.cells = response.data
          this.cells = this.cells.sort(this.sortFunction)
        })
    },
    onChange(selectedItemId) {
      this.newMap.shopItemId = selectedItemId
      const item = this.shopItems.find(x => x.id === selectedItemId)
      this.newMap.price = item.price;
      this.newMap.shopItemShortName = item.shortName;
    },
    onChangeForEdit(selectedItemId) {
      this.editMap.shopItemId = selectedItemId
      const item = this.shopItems.find(x => x.id === selectedItemId)
      this.editMap.price = item.price;
      this.editMap.shopItemShortName = item.shortName;
    },
    addMapping(){
      this.error = null;
      this.isSaving = true;
      this.$http.post('/vendingmachines/' + this.id + '/shop-items', this.newMap).
          then(response => {
            this.addMappingDialog = false;
            this.newMap = {
              shopItemId: null,
              vendingMachineId: this.id,
              amount: 0,
              button: 0,
              price: 0,
              shopItemShortName: 0,
            };
            this.getVendingMachineShopItems();
            this.cells = this.cells.sort(this.sortFunction)
            this.isSaving = false;
          }).
          catch(e => {
            this.error = e.response.data;
            console.log(e)
            this.isSaving = false;
          });
      
    },
    pickToAddShopItem(){
      this.newMap.button = this.items?.length + 1;
      this.addMappingDialog = true;
    },
    pickToEditShopItem(item){
      this.error = null;
      this.isSaving = false;
      this.editMap = item;
      this.editMappingDialog = true;
    },
    pickToDeleteMapping(item){
      this.editMap = item;
      this.deleteMappingDialog = true;
      this.isSaving = false;
    },
    editMapping(){
      this.error = null;
      this.isSaving = true;
      
      this.$http.put('/vendingmachines/' + this.id + '/shop-items/' + this.editMap.id, this.editMap).
        then(response => {
          this.editMappingDialog = false;
          this.getVendingMachineShopItems();
          this.cells = this.cells.sort(this.sortFunction)
          this.isSaving = false;
        }).
        catch(e => {
          this.error = e.response.data;
          console.log(e)
          this.isSaving = false;
        });
    },
    deleteMapping(){
      this.isSaving = true;
      this.$http.delete('/vendingmachines/' + this.id + '/shop-items/' + this.editMap.id).
        then(response => {
              this.deleteMappingDialog = false
              this.editMap = {};
              this.getVendingMachineShopItems();
              this.cells = this.cells.sort(this.sortFunction)
              this.isSaving = false;
            }).
            catch(e => {
              console.log(e)
              this.isSaving = false;
            });
    },
    
    sortFunction(a,b){
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    },
    sortCompareNumbers(a,b){
      return a - b;
    },
    changeEditedPrice(price){
      this.editMap.button = this.fltrdCellsEdit[0].name
    },
    changeAddedPrice(price){
      this.newMap.button = this.fltrdCellsAdd[0].name
    },
    async getCategories(){
      await this.$http.get('/ShopItemCategory')
        .then(response => {
          this.categoryItems = response.data
        })
    },
    async getActions(){
      await this.$http.get('/cellactions')
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.actions.push(...response.data)
          }
        })
    },
  }
}
</script>

<style>
.v-text-field.v-text-field--solo .v-input__control{
  min-height: 40px;
}
</style>